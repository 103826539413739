<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table px-0">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            注文管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button class="button-create-user" @click="downloadCSV()">
            CSV出力
          </button>
          <button
            v-on:click="gotoDashboard()"
            class="button-back-dashboard"
            style="background-color: #e5e5e5"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row align-items-center cpx-15">
        <div class="row-input col-4">
          <input
            class="form-control w-100"
            v-model="keywords"
            place-holder="検索"
            v-on:keyup.enter="getListOrders(1)"
          />
        </div>
        <div class="row d-flex col-7 px-3 align-items-center">
          <div class="row-input col-5 p-0">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="created_from"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListOrders(1)"
              ></date-picker>
            </div>
          </div>
          <div class="col-1 p-0 text-center">
            <span class="custom-span text-center p-0 justify-content-center">
              ~
            </span>
          </div>
          <div class="row-input col-5 p-0">
            <!-- <CInput type="date" v-model="created_to" max="9999-12-31" /> -->
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="created_to"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListOrders(1)"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-1 p-0 text-center">
          <b-button
            class="btn-common"
            v-on:click="resetFieldSearch()"
            style="background-color: #e5e5e5; color: #000"
          >
            リセット
          </b-button>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListOrders()"
          class="mr-4 btn-info"
          style="
            color: #fff;
            background-color: #39f !important;
            border-color: #39f;
          "
          >絞り込み検索</b-button
        >
        <b-button
          class="mr-4 btn-common"
          v-on:click="resetFieldSearch()"
          style="background-color: #e5e5e5; color: #000"
        >
          リセット
        </b-button>
      </div> -->
      <Tables
        class="cpx-15"
        :items="listOrder.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:total_amount="{ item }">
          <td>{{ Number(item.total_amount).toLocaleString("ja") }}</td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 1">確認待ち（銀行振込）</td>
          <td v-else-if="item.status_order == 2">承認済み</td>
          <td v-else-if="item.status_order == 3">
            キャンセル（結果：自動返金済み）
          </td>
          <td v-else-if="item.status_order == 4">
            キャンセル（結果：自動返金不可・手動で返金）
          </td>
          <td v-else-if="item.status_order == 5">
            キャンセル（返金を伴わない）
          </td>
          <td v-else-if="item.status_order == 6">自動決済</td>
          <td v-else-if="item.status_order == 7">決済失敗</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:logout_at="{ item }">
          <td>
            {{
              new Date(item.logout_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div>
              <router-link
                :to="{
                  name: $route.params.shopId ? 'EditOrder' : 'EditOrder domain',
                  params: { shopId: $route.params.shopId, id: item.id },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-share-size"
                  square
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  詳細
                </CButton>
              </router-link>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listOrder.total"
      >
        <p class="font-weight-bold">
          {{ listOrder.total }}件中{{ listOrder.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative row"
        v-if="listOrder.last_page"
      >
        <CPagination
          v-if="listOrder.total"
          :activePage.sync="page"
          :pages="listOrder.last_page"
          align="center"
          class="col-sm-5"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
            class="col-auto"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-user" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>注文詳細</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="blockNewUser()"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-user')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import Encoding from "encoding-japanese";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants";
import $ from "jquery";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListOrder",
  data() {
    return {
      fields: tableFields.ORDER,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      total_amount: "",
      title: "",
      name: "",
      status_order: "",
      keywords: "",
      optionStatusOrder: [
        { value: 1, text: "確認待ち（銀行振込）" },
        { value: 2, text: "承認済み" },
        { value: 3, text: "キャンセル（結果：自動返金済み）" },
        { value: 4, text: "キャンセル（結果：自動返金不可・手動で返金）" },
        { value: 5, text: "キャンセル（返金を伴わない）" },
        { value: 6, text: "自動決済" },
        { value: 7, text: "決済失敗" },
      ],
      created_from: "",
      created_to: "",
      limit: 50,
      page: 1,
      json_fields: {
        NO: "id",
        決済番号: "total_amount",
        コンテンツ名: "title",
        ユーザー名: "name",
        ステータス: "status_order",
        注文日時: "created_at",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListOrders(1);
  },
  computed: {
    ...mapGetters(["listOrder", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listOrder() {
      this.json_data = this.listOrder.data;
      this.page = this.listOrder.current_page;
    },
    page() {
      this.getListOrders(this.page);
    },
  },
  methods: {
    ...mapActions({ getListOrder: "getListOrder" }),
    ...mapActions({ blockUser: "blockUser" }),
    createUser() {
      this.$router.push({
        name: this.$route.params.shopId ? "create user" : "create user domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    getListOrders(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          // total_amount: this.total_amount,
          // content_title: this.title,
          // name: this.name,
          // status_order: this.status_order,
          created_from: this.created_from,
          created_to: this.created_to,
          limit: this.limit,
        },
      };
      this.getListOrder(formData);
    },
    changeLimitPage() {
      this.getListOrders(1);
    },
    async csvExport(datas, dataRequest) {
      const url = `${Urls.ADMIN}/${Urls.ORDER}/${Urls.EXPORT_ORDER}`;
      const method = "POST";
      let response = await Api.adminRequestServer({
        method,
        url,
        data: dataRequest,
      });
      var { data } = response.data;
      if (data.success === false) return datas;
      datas += data.data;
      this.index++;
      if (this.index % Constants.LIMIT_CSV == 0) {
        const getDate = new Date();
        const nameExcel =
          "注文一覧" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        let file = "NO,コンテンツ名,ユーザー名,金額(円),ステータス,注文日時\n";
        file += datas;
        datas = [];
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      if (data.data.status == "未承認") {
        dataRequest.id = data.data.id;
        datas = await this.csvExport(datas, dataRequest);
        return datas;
      }
      return datas;
    },
    async downloadCSV() {
      this.messageError = {};
      this.messageResponse = "";
      this.messagerErr = "";

      try {
        this.index = 0;
        var datas = [];
        let dataRequest = {
          id: 0,
          shop_id: this.shop_id,
          keywords: this.keywords,
          created_from: this.created_from,
          created_to: this.created_to,
          limit: this.limit,
        };
        datas = await this.csvExport(datas, dataRequest);
        if (Object.keys(datas).length == 0) {
          if (this.index == 0) {
            this.messageResponse = "データがありません";
          } else {
            this.messageSuccess = "ダウンロードが完了しました";
          }
          // $("body").removeClass("wait");
          return;
        }
        const getDate = new Date();
        const nameExcel =
          "注文一覧" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        dataRequest.created_from.replaceAll("-", "") +
          "-" +
          dataRequest.created_to.replaceAll("-", "") +
          "_" +
          (Math.floor(this.index / Constants.LIMIT_CSV) + 1) +
          ".csv";
        let file = "NO,コンテンツ名,ユーザー名,金額(円),ステータス,注文日時\n";
        file += datas;
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageSuccess = "ダウンロードが完了しました";
        // $("body").removeClass("wait");
      } catch (e) {
        // $("body").removeClass("wait");
        this.messageResponse = "システムエラーが発生しました";
        console.log(e);
      }
    },
    changeStatus(value) {
      this.status_order = value;
    },
    resetFieldSearch() {
      this.total_amount = "";
      this.title = "";
      this.name = "";
      this.status_order = "";
      this.created_from = "";
      this.created_to = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          total_amount: this.total_amount,
          content_title: this.title,
          name: this.name,
          status_order: this.status_order,
          created_from: this.created_from,
          created_to: this.created_to,
          limit: this.limit,
        },
      };
      this.getListOrder(formData);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
}
</style>
